<template>
	<div v-if="!isLoading" class="c-app flex-row align-items-center authen-page">
		<CContainer>
			<CRow v-if="isValid === true" class="justify-content-center">
				<CCol md="8">
					<CCardGroup>
						<!-- <CCard
							color="primary"
							text-color="white"
							class="text-center py-5 col-4 d-md-down-none"
							body-wrapper
						>
							<CCardBody class="h-100">
								<img v-lazy="logo" alt class="logo" />
							</CCardBody>
						</CCard> -->
						<CCard class="p-4">
							<CCardBody>
								<ValidationObserver ref="form" v-slot="{ handleSubmit }">
									<form @submit.prevent="handleSubmit(resetPwd)">
										<h1 class="pb-4">
											{{ isReset ? $t("ResetPwd.Title") : $t("ResetPwd.Title_Reset") }}
										</h1>
										<div class="form-group">
											<ValidationProvider
												v-slot="{ classes, errors }"
												name="auth_new_password"
												:rules="`requiredChar|max:${maxLength}|min:${minLength}`"
											>
												<div :class="classes">
													<div class="position-relative">
														<input
															v-model="resetForm.password"
															class="form-control"
															autocomplete="curent-password"
															:placeholder="$t('ResetPwd.Password')"
															:type="showPwd ? 'password' : 'text'"
															:maxlength="maxLength"
														/>
														<div v-if="resetForm.password" @click="showPassword">
															<span v-if="!showPwd" class="input-group-text icon-input">
																<font-awesome-icon
																	:title="$t('ResetPwd.hidePwd')"
																	:icon="['fas', 'eye-slash']"
																/>
															</span>
															<span v-else class="input-group-text icon-input">
																<font-awesome-icon
																	:title="$t('ResetPwd.showPwd')"
																	:icon="['fas', 'eye']"
																/>
															</span>
														</div>
													</div>
													<div class="error-mess">
														{{ errors[0] }}
													</div>
												</div>
											</ValidationProvider>
										</div>

										<div class="form-group">
											<ValidationProvider
												v-slot="{ classes, errors }"
												name="auth_confirm_password"
												:rules="`requiredChar|max:${maxLength}|min:${minLength}|confirmedBy:@auth_new_password`"
											>
												<div :class="classes">
													<div class="position-relative">
														<input
															v-model="resetForm.confirmPassword"
															class="form-control"
															autocomplete="curent-password"
															:placeholder="$t('ResetPwd.ConfirmPassword')"
															:type="showConfirmPwd ? 'password' : 'text'"
															:maxlength="maxLength"
														/>
														<div v-if="resetForm.confirmPassword" @click="showConfirmPassword">
															<span v-if="!showConfirmPwd" class="input-group-text icon-input">
																<font-awesome-icon
																	:title="$t('ResetPwd.hidePwd')"
																	:icon="['fas', 'eye-slash']"
																/>
															</span>

															<span v-else class="input-group-text icon-input">
																<font-awesome-icon
																	:title="$t('ResetPwd.showPwd')"
																	:icon="['fas', 'eye']"
																/>
															</span>
														</div>
													</div>
													<div class="error-mess">
														{{ errors[0] }}
													</div>
												</div>
											</ValidationProvider>
										</div>

										<CRow v-if="!isReset">
											<CCol col="12" class="text-right">
												<button type="submit" class="px-4 btn btn-outline-custom">
													{{ $t("ResetPwd.Submit") }}
												</button>
											</CCol>
										</CRow>
										<CRow v-else>
											<CCol col="6" class="text-left">
												<button
													type="button"
													class="px-4 btn btn-outline-danger"
													@click="gotoLogin"
												>
													{{ $t("ResetPwd.Cancel") }}
												</button>
											</CCol>
											<CCol col="6" class="text-right">
												<button class="px-4 btn btn-outline-custom" type="submit">
													{{ $t("ResetPwd.Submit") }}
												</button>
											</CCol>
										</CRow>
									</form>
								</ValidationObserver>
							</CCardBody>
						</CCard>
					</CCardGroup>
				</CCol>
			</CRow>
			<div v-if="isValid === false" class="text-center">
				<h1 class="pb-4">
					{{ isReset ? $t("ResetPwd.Title") : $t("ResetPwd.Title_Reset") }}
				</h1>
				<div>{{ msgErr }}</div>
			</div>
		</CContainer>
		<!-- <CustomModal
			:is-show-submit="false"
			:cancel-color="'outline-custom'"
			:cancel-text="$t('Button.OK')"
			@cancel-modal="gotoLogin"
		>
			<template #content>
				{{ isReset ? $t("ResetPwd.resetAgainSuccess") : $t("ResetPwd.resetSuccess") }}
			</template>
		</CustomModal> -->
	</div>
</template>

<script>
import logo from "@/assets/img/logo_small.jpg"
import { RESET_PASSWORD, CHECK_TOKEN_VALID } from "../store/action-types"
import { createNamespacedHelpers } from "vuex"
const { mapActions, mapState } = createNamespacedHelpers("authentication")

export default {
	name: "ResetPassword",

	data() {
		return {
			logo,
			showPwd: true,
			showConfirmPwd: true,
			resetForm: {
				password: null,
				confirmPassword: null,
			},
			maxLength: process.env.VUE_APP_INPUT_MAX_LENGTH,
			minLength: process.env.VUE_APP_INPUT_MIN_LENGTH,
			isReset: false,
		}
	},

	computed: {
		...mapState({
			isValid: "resetTokenValid",
			msgErr: "tokenErr",
			isLoading: "isLoading",
			emailForgot: "emailForgot",
		}),
	},

	watch: {},

	created() {
		this.checkValid()
		this.isReset = this.$route.query.isReset || false
	},

	methods: {
		...mapActions({ RESET_PASSWORD, CHECK_TOKEN_VALID }),

		showPassword() {
			this.showPwd = !this.showPwd
		},

		showConfirmPassword() {
			this.showConfirmPwd = !this.showConfirmPwd
		},

		resetPwd: async function () {
			const params = {
				// token: this.$route.query.token || null,
				password: this.resetForm.password,
				...this.emailForgot,
			}
			await this.RESET_PASSWORD({ ...params })
		},

		gotoLogin() {
			this.changeModalSuccess()
			this.$router.push("/login")
		},

		checkValid: async function () {
			// const params = {
			// 	token: this.$route.query.token || null,
			// }
			await this.CHECK_TOKEN_VALID()
		},

		changeModalSuccess(status = false) {
			this.$store.commit("set", ["modalShow", status])
		},
	},
}
</script>
